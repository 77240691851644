import React, { Component } from 'react';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Redirect, Switch } from 'react-router';
import store from '../store';
import Header from './Header';
import OrdersListPage from '../routes/orders/containers/OrdersListPage';
import OrderPage from '../routes/orders/containers/OrderPage';
import ReviewsPage from '../routes/orders/reviews/containers/ReviewsPage';
import BasketsPage from '../routes/orders/baskets/containers/BasketsPage';
import CatalogListPage from '../routes/catalog/containers/CatalogListPage';
import CatalogUploadPage from '../routes/catalog/CatalogUploadPage';
import CatalogExportPage from '../routes/catalog/CatalogExportPage';
import CatalogExportGTINPage from '../routes/catalog/CatalogExportGTINPage';
import ProductPage from '../routes/catalog/containers/ProductPage';
import BannersListPage from '../routes/marketer/banners/containers/BannersListPage';
import BannerswcListPage from '../routes/marketer/bannerswc/containers/BannerswcListPage';
import SubscriptionsListPage from '../routes/marketer/subscriptions/containers/SubscriptionsListPage';
import TopmenusListPage from '../routes/layouts/topmenu/containers/TopmenusListPage';
import BannerPage from '../routes/marketer/banners/containers/BannerPage';
import BannerwcPage from '../routes/marketer/bannerswc/containers/BannerwcPage';
import TopmenuPage from '../routes/layouts/topmenu/containers/TopmenuPage';
import LoginPage from './LoginPage';
import { setAuthResult } from '../modules/signin';
import axios from 'axios';
const Cookies = require('js-cookie');


const theme = createMuiTheme();

const wrapperStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
};


class App extends Component {

  componentDidMount() {

    let checkAccess = () => {
      if (window.location.search.search('\\?sessionHash=') === 0) {
        let sessionHash = window.location.search.replace('?sessionHash=', '');
        if (sessionHash) {
          Cookies.set('sessionHash', sessionHash, { expires: 365, path: '/' });
          window.location.search = '';
          return;
        }
      }

      if (!Cookies.get('sessionHash')) {
        window.location.href = '/login';
        return;
      }

      axios({
        url: '/api/core/login/check'
      }).then(result => {
        store.dispatch(setAuthResult(result.data.success));

        if (!result.data.success) {
          window.location.href = '/login';
        }
      })
    };

    if (window.location.pathname !== '/login') {
      checkAccess();
    }
  }

  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <Router>
            <div style={wrapperStyle}>
              <Header />
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/" render={() => <Redirect to="/operator/orders" />} />
              <Route exact path="/operator/orders" component={OrdersListPage} />
              <Switch>
                <Route path="/operator/orders/new/:typeId" component={OrderPage} />
                <Route path="/operator/orders/new-by-cart/:id" component={OrderPage} />
                <Route path="/operator/orders/:id" component={OrderPage} />
              </Switch>
              <Route exact path="/operator/reviews" component={ReviewsPage} />
              <Route exact path="/operator/baskets" component={BasketsPage} />
              <Route exact path="/catalog/products" component={CatalogListPage} />
              <Switch>
                <Route path="/catalog/products/:id" component={ProductPage} />
              </Switch>
              <Route path="/catalog/upload" component={CatalogUploadPage} />
              <Route path="/catalog/export" component={CatalogExportPage} />
              <Route path="/catalog/exportgtin" component={CatalogExportGTINPage} />
              <Route exact path="/marketer/banners" component={BannersListPage} />
              <Switch>
                <Route path="/marketer/banners/new" component={BannerPage} />
                <Route path="/marketer/banners/:id" component={BannerPage} />
              </Switch>
              <Route exact path="/marketer/bannerswc" component={BannerswcListPage} />
              <Switch>
                <Route path="/marketer/bannerswc/new" component={BannerwcPage} />
                <Route path="/marketer/bannerswc/:id" component={BannerwcPage} />
              </Switch>
              <Route exact path="/layouts/topmenu" component={TopmenusListPage} />
              <Switch>
                <Route path="/layouts/topmenu/new" component={TopmenuPage} />
                <Route path="/layouts/topmenu/:id" component={TopmenuPage} />
              </Switch>
              <Route path="/marketer/subscriptions" component={SubscriptionsListPage} />
            </div>
          </Router>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
