import React from 'react';
import Layout from '../../components/Layout';
import {connect} from 'react-redux';
import {withStyles} from 'material-ui/styles';
import {exportProductsGTIN} from '../../modules/exportinggtin';
import SmartButton from '../../components/SmartButton';


const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit * 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  button: {
    margin: theme.spacing.unit,
  },
  link: {
    marginTop: 10
  }
});


class CatalogExportGTINPage extends React.Component {
  render() {
    return (
      <Layout
        content={this.renderContent()}
      />
    );
  }

  renderContent() {
    const {classes} = this.props;
    return (
      <div className={classes.container}>
        <SmartButton
          className={classes.button}
          onClick={this.props.exportProductsGTIN}
          processing={this.props.processing}
          processed={this.props.processed}
        >
          Экспорт товаров для GTIN
        </SmartButton>

        {this.props.link &&
          <a href={this.props.link} className={classes.link}>Скачать</a>
        }
      </div>
    )
  }
}



export default connect(
  state => state.exportinggtin,
  (dispatch, ownProps) => ({
    exportProductsGTIN() {
      dispatch(exportProductsGTIN());
    }
  })
)(withStyles(styles)(CatalogExportGTINPage));
