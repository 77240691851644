import React, {useState} from 'react';
import { connect } from 'react-redux';
import Table, { TableHead, TableBody, TableRow, TableCell } from 'material-ui/Table';
import Button from 'material-ui/Button';
import Checkbox from 'material-ui/Checkbox';
import { Block, Row } from '../../../components/SubLayout';
import { setRemainsEnabled, setRemainsDisabled } from '../../../modules/products/index';
import CustomSelect from '../../../components/CustomSelect';
import R from "ramda";

const ProductRemains = ({ remains, product, setRemainsEnabled, setRemainsDisabled , loadOneProductRemains }) => {

    const [sizeForAdd, setSizeForAdd]=useState('');
    const [stockForAdd, setStockForAdd]=useState('');

    const handleChange = (productId, remain) => {
        if (remain['is_enabled'] === '1') {
            setRemainsDisabled(productId, remain['stock_id'], remain['size_name']);
        } else {
            setRemainsEnabled(productId, remain['stock_id'], remain['size_name']);
        }
    }

    const addSize = ( ) => {
            setRemainsEnabled(product.ID,stockForAdd, sizeForAdd);
        loadOneProductRemains();
        setSizeForAdd('');
        setStockForAdd('');
           }

    const handleChangeRemain = (e) => {
        setSizeForAdd(e);
    };


    const handleChangeStock = (e) => {
        setStockForAdd(e);
    };

    const getTypeOptionsFromArray = (ownProps) => {
        if (!ownProps) {
            return [];
        }
        return R.memoize(options => options?.map(
            (size) => ({ id: size, text: size })
        ))(ownProps);
    }

    const getStocksFromArray = (ownProps) => {
        if (!ownProps) {
            return [];
        }
        return R.memoize(options => options?.map(
            ({stock_id, stock_name}) => ({ id:stock_id, text: stock_name })
        ).reduce((acc, current) => {
            const x = acc.find(item => item.id === current.id);
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []))(ownProps);
    }

    const renderRemainAdd = () => {
        return (
            <Row>
                <CustomSelect
                    options={getTypeOptionsFromArray(product.ALL_SIZES)}
                    value={sizeForAdd}
                    onChange={handleChangeRemain}
                />
                <CustomSelect
                    options={getStocksFromArray(remains)}
                    value={stockForAdd}
                    onChange={handleChangeStock}
                />
            <Button onClick={addSize}>Add    </Button>
            </Row>
        );

    }

    const renderHeader = () => {
        return (
            <TableHead>
                <TableRow>
                    <TableCell>
                        Размер
                    </TableCell>
                    <TableCell>
                        Склад
                    </TableCell>
                    <TableCell>
                        Остатки
                    </TableCell>
                    <TableCell>
                        Активность
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    const renderTable = () => {
        return (
            <TableBody>
                {remains.map((remain, index) => (
                    renderRemainsRow(remain, index)
                ))}
            </TableBody>
        );
    }

    const renderRemainsRow = (remain, index) => {
        const isEnabled = remain['is_enabled'] === '1' ? true : false;
        return (
            <TableRow key={index}>
                <TableCell>
                    {remain['size_name']}
                </TableCell>
                <TableCell>
                    {remain['stock_name']}
                </TableCell>
                <TableCell>
                    {remain['qty']}
                </TableCell>
                <TableCell>
                    <Checkbox
                        checked={isEnabled}
                        onChange={() => handleChange(product.ID, remain)}
                    />
                </TableCell>
            </TableRow>
        );
    }

    return (
        <Block title="Остатки">
            <Table>
                {renderHeader()}
                {renderTable()}
            </Table>
            {product.ALL_SIZES && product.ALL_SIZES[0] && renderRemainAdd()}
        </Block>
    );
}


export default connect (
  state => ({}),
  dispatch => ({
    setRemainsEnabled(productId, stockId, sizeName) {
      dispatch(setRemainsEnabled(productId, stockId, sizeName));
    },
    setRemainsDisabled(productId, stockId, sizeName) {
      dispatch(setRemainsDisabled(productId, stockId, sizeName));
    }
  })
)(ProductRemains);
