const types = require('./types');

export const loadTopmenusData = () => ({
  type: types.LOAD_TOPMENUS_DATA
});

export const removeTopmenu = id => ({
  type: types.REMOVE_TOPMENU,
  id
});

export const setPage = page => ({
  type: types.SET_PAGE,
  page
});

export const loadOneTopmenu = id => ({
  type: types.LOAD_ONE_TOPMENU,
  id
});

export const loadTopmenuTypes = () => ({
  type: types.LOAD_TOPMENU_TYPES
});

export const uploadTopmenuImage = data => ({
  type: types.UPLOAD_TOPMENU_IMAGE,
  data
});

export const removeTopmenuHorizontalImage = id => ({
  type: types.REMOVE_TOPMENU_HORIZONTAL_IMAGE,
  id
})

export const removeTopmenuVerticalImage = id => ({
  type: types.REMOVE_TOPMENU_VERTICAL_IMAGE,
  id
})

export const setDraftTopmenuField = (field, value) => ({
  type: types.SET_DRAFT_TOPMENU_FIELD,
  field,
  value
});

export const saveTopmenu = history => ({
  type: types.SAVE_TOPMENU,
  history
});

export const toggleActivityType = id => ({
  type: types.TOGGLE_ACTIVITY_TYPE,
  id
});

export const toggleType = id => ({
  type: types.TOGGLE_TYPE,
  id
});
