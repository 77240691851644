export const LOAD_TOPMENUS_DATA = 'topmenu/LOAD_TOPMENUS_DATA';
export const LOAD_TOPMENUS_DATA_COMPLETED = 'topmenu/LOAD_TOPMENUS_DATA_COMPLETED';

export const LOAD_ONE_TOPMENU = 'topmenu/LOAD_ONE_TOPMENU';
export const LOAD_ONE_TOPMENU_COMPLETED = 'topmenu/LOAD_ONE_TOPMENU_COMPLETED';

export const REMOVE_TOPMENU = 'topmenu/REMOVE_TOPMENU';

export const SET_PAGE = 'topmenu/SET_PAGE';

export const LOAD_TOPMENU_TYPES = 'topmenu/LOAD_TOPMENU_TYPES';
export const LOAD_TOPMENU_TYPES_COMPLETED = 'topmenu/LOAD_TOPMENU_TYPES_COMPLETED';

export const UPLOAD_TOPMENU_IMAGE = 'topmenu/UPLOAD_TOPMENU_IMAGE';

export const SET_DRAFT_TOPMENU_FIELD = 'topmenu/SET_DRAFT_TOPMENU_FIELD';

export const SAVE_TOPMENU = 'topmenu/SAVE_TOPMENU';

export const TOGGLE_ACTIVITY_TYPE = 'topmenu/TOGGLE_ACTIVITY_TYPE';
export const TOGGLE_TYPE = 'topmenu/TOGGLE_TYPE';

export const REMOVE_TOPMENU_HORIZONTAL_IMAGE = 'topmenu/REMOVE_TOPMENU_HORIZONTAL_IMAGE';
export const REMOVE_TOPMENU_VERTICAL_IMAGE = 'topmenu/REMOVE_TOPMENU_VERTICAL_IMAGE';
