import React from 'react';
import {withStyles} from 'material-ui/styles';
import TextField from 'material-ui/TextField';
import {Container, Block, Row, Cell} from '../../../../components/SubLayout';
import CustomSelect from '../../../../components/CustomSelect';
import FormControl from 'material-ui/Form/FormControl';
import Input from 'material-ui/Input';
import IconButton from 'material-ui/IconButton';
import Icon from 'material-ui/Icon';
import R from 'ramda';


const styles = {
  img: {
    maxWidth: 300,
    maxHeight: 300
  }
};

const topmenuActivityOptions = [
  {id: '0', text: 'Нет'},
  {id: '1', text: 'Да'}
];

class TopmenuLayout extends React.PureComponent {
  getTypeOptions() {
    if (!this.props.types) {
      return [];
    }
    return R.memoize(options => options.map(
      ({id, name}) =>
        ({id, text: name})
    ))(this.props.types);
  }

  handleUploadImage = type => event => {
    const data = new FormData();
    data.append('file', event.target.files[0]);
    data.append('id', this.props.topmenu.id);
    data.append('type', type);
    this.props.uploadImage(data);
  }

  render() {
    return (
      <Container>
        {this.renderBlockInfo()}
        {this.props.topmenu.id &&
          this.renderBlockImages()
        }
      </Container>
    );
  }

  renderBlockInfo() {
    const topmenu = this.props.topmenu;
const textfielditems = Array.isArray(topmenu.textfield) ? 
topmenu.textfield[0].items :"";
    return (
      <Block title="Раздел меню">
        <Row>
          <Cell>ИД</Cell>
          <Cell>
            <strong>{topmenu.id || ''}</strong>
          </Cell>
        </Row>
        <Row>
          <Cell>Адрес</Cell>
          <Cell>
            <TextField value={topmenu.url || ''} onChange={this.props.setFieldValue(['url'])}/>
          </Cell>
        </Row>
        <Row>
          <Cell>Активен</Cell>
          <Cell>
            <CustomSelect
              options={topmenuActivityOptions}
              value={topmenu.is_active}
              onChange={this.props.setFieldValue(['is_active'])}
            />
          </Cell>
        </Row>
        <Row>
          <Cell>Порядок</Cell>
		  {Array.isArray(textfielditems) ?
		  (textfielditems.map(
		        ({name, path}, index) =>
				        (<div><Cell>
						<TextField value={name  || ''} onChange={this.props.setFieldValue(['textfield',0,'items', String(index) ,'name'])}/>
          </Cell><Cell>
						<TextField value={path  || ''} onChange={this.props.setFieldValue(['textfield',0,'items', String(index) ,'path'])}/>
          </Cell></div>)
				)) : (
		  topmenu.textfield && topmenu.textfield.map((value, index) => Object.entries(value['items']).map((value, index) => 
		  <div>
		  <Cell>
						<TextField value={value[1].name  || ''} onChange={this.props.setFieldValue(['textfield',0,'items', String(index) ,'name'])}/>
			</Cell><Cell>
						<TextField value={value[1].path  || ''} onChange={this.props.setFieldValue(['textfield',0,'items', String(index) ,'path'])}/>
		          </Cell>
				  </div>))
		  )}
          <Cell>
						<TextField value='' onChange={this.props.setFieldValue(['textfield',0,'items', 7 ,'name'])}/>
          </Cell>
		   <Cell>
						<TextField value='' onChange={this.props.setFieldValue(['textfield',0,'items', 7 ,'path'])}/>
          </Cell>
        </Row>
        <Row>
          <Cell>Название</Cell>
          <Cell>
            <TextField value={topmenu.name || ''} onChange={this.props.setFieldValue(['name'])}/>
          </Cell>
        </Row>
        <Row>
          <Cell>Тип</Cell>
          <Cell>
            <CustomSelect
              options={this.getTypeOptions()}
              value={topmenu.type_id}
              onChange={this.props.setFieldValue(['type_id'])}
            />
          </Cell>
        </Row>
		<Row>
          <Cell>Уровень</Cell>
          <Cell>
            <TextField value={topmenu.steplevel || ''} onChange={this.props.setFieldValue(['steplevel'])}/>
          </Cell>
        </Row>
        <Row>
          <Cell>Интервал показа</Cell>
          <Cell>
            <TextField label="От" value={topmenu.date_show_from || ''} onChange={this.props.setFieldValue(['date_show_from'])}/>
            <TextField label="До" value={topmenu.date_show_to || ''} onChange={this.props.setFieldValue(['date_show_to'])}/>
          </Cell>
        </Row>
      </Block>
    );
  }

  renderBlockImages() {
    const topmenu = this.props.topmenu;

    return (
      <Block title="Изображения">
        <Row>
          <Cell>
            Горизонтальное
            <div style={{display: 'flex', alignItems: 'center'}}>
              <FormControl onChange={this.handleUploadImage('horizontal')}>
                <Input type="file" accept="image/jpeg, image/png"/>
              </FormControl>

              {!!topmenu.image_url &&
                <IconButton title="Удалить" onClick={this.props.removeTopmenuHorizontalImage}>
                  <Icon className="material-icons">delete_forever</Icon>
                </IconButton>
              }
            </div>
          </Cell>
          <Cell>
            {!!topmenu.image_url &&
              <img
                alt="Баннер горизонтальный"
                className={this.props.classes.img}
                src={topmenu.image_url || ''}
              />
            }
          </Cell>
        </Row>
        <Row>
          <Cell>
            Вертикальное
            <div style={{display: 'flex', alignItems: 'center'}}>
              <FormControl onChange={this.handleUploadImage('vertical')}>
                <Input type="file" accept="image/jpeg, image/png"/>
              </FormControl>

              {!!topmenu.image_vertical_url &&
                <IconButton title="Удалить" onClick={this.props.removeTopmenuVerticalImage}>
                  <Icon className="material-icons">delete_forever</Icon>
                </IconButton>
              }
            </div>
          </Cell>
          <Cell>
            {!!topmenu.image_vertical_url &&
              <img
                alt="Баннер вертикальный"
                className={this.props.classes.img}
                src={topmenu.image_vertical_url || ''}
              />
            }
          </Cell>
        </Row>
      </Block>
    );
  }
}

export default withStyles(styles)(TopmenuLayout);
