import {put, all, takeLatest, select} from 'redux-saga/effects';
import axios from 'axios';
import qs from 'qs';
import R from 'ramda';
const types = require('./types');
const {
  getTopmenusPage,
  getTopmenusPageSize,
  getTopmenu,
  getTopmenusActivityTypesSelected,
  getTopmenusTypesSelected
} = require('../../../selectors').default;


function* loadTopmenusDataSaga() {
  const state = yield select();

  const page = getTopmenusPage(state);
  const pageSize = getTopmenusPageSize(state);
  const offset = pageSize * (page - 1);

  const activityTypes = getTopmenusActivityTypesSelected(state);

  const result = yield axios({
    method: 'GET',
    url: '/api/layouts/topmenu/list',
    params: R.pickBy((val, key) => !!val, {
      offset,
      is_active: activityTypes.length === 1 ? activityTypes[0] : [],
      type_id: getTopmenusTypesSelected(state)
    }),
    paramsSerializer(params) {
      return qs.stringify(params, {arrayFormat: 'brackets'});
    },
    withCredentials: true
  });

  yield put({
    type: types.LOAD_TOPMENUS_DATA_COMPLETED,
    topmenus: result.data.topmenus,
    pageSize: result.data.pageSize,
    total: result.data.total
  });
}

function* removeTopmenuSaga({id}) {
  yield axios({
    method: 'POST',
    url: '/api/layouts/topmenu/delete',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      id
    })
  });

  yield put({type: types.LOAD_TOPMENUS_DATA});
}

function* loadOneTopmenuSaga({id}) {
  let topmenu;
  if (id === 0) {
    topmenu = {};
  } else {
    const result = yield axios({
      method: 'GET',
      url: '/api/layouts/topmenu/view',
      params: {
        id
      },
      withCredentials: true
    });
    topmenu = result.data;
  }

  yield put({
    type: types.LOAD_ONE_TOPMENU_COMPLETED,
    topmenu
  });
}

function* loadTopmenuTypesSaga() {
  const result = yield axios({
    method: 'GET',
    url: '/api/layouts/topmenu/types',
    withCredentials: true
  });

  yield put({
    type: types.LOAD_TOPMENU_TYPES_COMPLETED,
    topmenuTypes: result.data
  });
}

function* uploadTopmenuImageSaga({data}) {
  const result = yield axios({
    method: 'POST',
    url: '/api/layouts/topmenu/upload',
    data
  });

  if (result.status !== 200) {
    console.error(result);
    return;
  }

  yield put({
    type: types.LOAD_ONE_TOPMENU_COMPLETED,
    topmenu: result.data
  });
}

function* removeTopmenuImageSaga({id, type}) {
  const result = yield axios({
    method: 'POST',
    url: `/api/layouts/topmenu/${type === 'horizontal' ? 'removeHorizontalImage' : 'removeVerticalImage'}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      id
    })
  });

  yield put({
    type: types.LOAD_ONE_TOPMENU_COMPLETED,
    topmenu: result.data
  });
}

function* removeTopmenuHorizontalImageSaga({id}) {
  yield removeTopmenuImageSaga({id, type: 'horizontal'})
}

function* removeTopmenuVerticalImageSaga({id}) {
  yield removeTopmenuImageSaga({id, type: 'vertical'})
}

function* saveTopmenuSaga({history}) {
  const state = yield select();
  const topmenu = getTopmenu(state);

  const result = yield axios({
    method: 'POST',
    url: '/api/layouts/topmenu/save',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: qs.stringify({
      ...topmenu
    })
  });

  if (topmenu.id) {
    yield put({
      type: types.LOAD_ONE_TOPMENU_COMPLETED,
      topmenu: result.data
    });
  } else {
    yield put({
      type: types.LOAD_ONE_TOPMENU,
      id: result.data.id
    });
    history.push(`/layouts/topmenu/${result.data.id}`);
  }
}

export default function*() {
  yield all([
    takeLatest(types.LOAD_TOPMENUS_DATA, loadTopmenusDataSaga),
    takeLatest(types.REMOVE_TOPMENU, removeTopmenuSaga),
    takeLatest(types.LOAD_ONE_TOPMENU, loadOneTopmenuSaga),
    takeLatest(types.LOAD_TOPMENU_TYPES, loadTopmenuTypesSaga),
    takeLatest(types.UPLOAD_TOPMENU_IMAGE, uploadTopmenuImageSaga),
    takeLatest(types.REMOVE_TOPMENU_HORIZONTAL_IMAGE, removeTopmenuHorizontalImageSaga),
    takeLatest(types.REMOVE_TOPMENU_VERTICAL_IMAGE, removeTopmenuVerticalImageSaga),
    takeLatest(types.SAVE_TOPMENU, saveTopmenuSaga)
  ]);
}
