import React from 'react';
import Layout from '../../../../components/Layout';
import {connect} from 'react-redux';
import Table, {TableHead, TableBody} from 'material-ui/Table';
import Button from 'material-ui/Button';
import Icon from 'material-ui/Icon';
import {withRouter} from 'react-router';
import TopmenusListHeaderRow from '../components/TopmenusListHeaderRow';
import TopmenusListRow from '../components/TopmenusListRow';
import TopmenusListSidebar from './TopmenusListSidebar';
import Pagination from '../../../../components/Pagination';
import Loader from '../../../../components/Loader';
import SmartFab from '../../../../components/SmartFab';
import {loadTopmenusData, setPage, removeTopmenu} from '../../../../modules/layouts/topmenu/index';

const {
  getTopmenusList,
  getTopmenusTotal,
  getTopmenusPage,
  getTopmenusPageSize,
  getTopmenusLoading,
  getTopmenusLoaded
} = require('../../../../selectors').default;


const styles = {
  top: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between'
  },
  paginationContainer: {
    flex: '0 1 auto'
  },
  buttonsContainer: {
    flex: '0 1 auto'
  },
  topmenuButton: {
    marginBottom: 5,
    marginLeft: 10
  },
  notFound: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    textAlign: 'center',
    marginTop: -10
  },
  refresherContainer: {
    flex: 'none',
    margin: '0 20px'
  }
};

class TopmenusListPage extends React.PureComponent {
  componentDidMount() {
    this.props.loadTopmenusData();
  }

  render() {
    if (!this.props.loaded && !this.props.loading) {
      return null;
    }

    return (
      <Layout
        content={this.props.loading && this.props.topmenus.length === 0 ? <Loader /> : this.renderContent()}
        sidebar={this.props.loaded && <TopmenusListSidebar/>}
      />
    );
  }

  renderContent() {
    if (this.props.topmenus.length === 0) {
      return (
        <div style={styles.notFound}>
          Баннеры не найдены
        </div>
      );
    }

    return (
      <div>
        <div style={styles.top}>
          {this.renderPagination()}
          {this.renderRefresher()}
          {this.renderButton()}
        </div>
        {this.renderTable()}
      </div>
    );
  }

  renderPagination() {
    return (
      <div style={styles.paginationContainer}>
        <Pagination
          total={this.props.total}
          current={this.props.page}
          pageSize={this.props.pageSize}
          onChange={this.props.handlePageChange}
        />
      </div>
    );
  }

  renderRefresher() {
    return (
      <div style={styles.refresherContainer}>
        <SmartFab processing={this.props.loading} onClick={this.props.loadTopmenusData} icon={'refresh'}/>
      </div>
    );
  }

  renderButton() {
    return (
      <div style={styles.buttonsContainer}>
        <Button
          raised
          color="primary"
          onClick={this.props.handleTopmenuCreate}
          style={styles.topmenuButton}
        >
          Баннер <Icon className="material-icons">note_add</Icon>
        </Button>
      </div>
    );
  }

  renderTable() {
    return (
      <Table>
        <TableHead>
          <TopmenusListHeaderRow/>
        </TableHead>
        <TableBody>
          {this.props.topmenus.map((topmenu, index) =>
            <TopmenusListRow
              key={index}
              topmenu={topmenu}
              onOpen={this.props.handleTopmenuOpen}
              onRemove={this.props.handleTopmenuRemove}
            />
          )}
        </TableBody>
      </Table>
    );
  }
}

export default withRouter(connect(
  state => ({
    topmenus: getTopmenusList(state),
    total: getTopmenusTotal(state),
    page: getTopmenusPage(state),
    pageSize: getTopmenusPageSize(state),
    loading: getTopmenusLoading(state),
    loaded: getTopmenusLoaded(state)
  }),
  (dispatch, ownProps) => ({
    loadTopmenusData() {
      dispatch(loadTopmenusData());
    },
    handlePageChange(number) {
      dispatch(setPage(number));
      dispatch(loadTopmenusData());
    },
    handleTopmenuOpen(topmenu) {
      ownProps.history.push(`/layouts/topmenu/${topmenu.id}`);
    },
    handleTopmenuRemove(id) {
      if (window.confirm(`Вы уверены, что хотите УДАЛИТЬ баннер ИД ${id}?`)) {
        dispatch(removeTopmenu(id));
      }
    },
    handleTopmenuCreate() {
      ownProps.history.push('/layouts/topmenu/new');
    }
  })
)(TopmenusListPage));
