import R from 'ramda';
const types = require('./types');


const initialState = {
  topmenus: [],
  topmenu: {},
  pageSize: 40,
  total: 0,
  page: 1,
  topmenuTypes: [],
  loading: false,
  loaded: false,
  filters: {
    activity: [],
    types: []
  }
};

const toggleArrayItem = (path, value, state) => {
    const lens = R.lensPath(path);

    let values = R.view(lens, state) || [];
    const index = R.indexOf(value, values);
    const transform = index === -1 ? R.append(value) : R.remove(index, 1);

    return R.set(lens, transform(values), state);
};

const toggleFilterArrayItem = (path, value, state) =>
  ({...toggleArrayItem(path, value, state), page: 1});

export default function (state = initialState, action) {
  switch(action.type) {
    case types.LOAD_TOPMENUS_DATA:
      return {
        ...state,
        loading: true
      };
    case types.LOAD_TOPMENUS_DATA_COMPLETED:
      return {
        ...state,
        topmenus: action.topmenus,
        pageSize: action.pageSize,
        total: action.total,
        loading: false,
        loaded: true
      };
    case types.SET_PAGE:
      return {...state, page: action.page};
    case types.REMOVE_TOPMENU:
      return {
        ...state,
        topmenus: state.topmenus.filter(row => row.id !== action.id)
      };
    case types.LOAD_ONE_TOPMENU_COMPLETED:
      return {...state, topmenu: action.topmenu};
    case types.LOAD_TOPMENU_TYPES_COMPLETED:
      return {...state, topmenuTypes: action.topmenuTypes};
    case types.SET_DRAFT_TOPMENU_FIELD:
      return R.assocPath(['topmenu', ...action.field], action.value, state);
    case types.TOGGLE_ACTIVITY_TYPE:
      return toggleFilterArrayItem(['filters', 'activity'], action.id, state);
    case types.TOGGLE_TYPE:
      return toggleFilterArrayItem(['filters', 'types'], action.id, state);
    default:
      return state;
  }
}
