import R from 'ramda';


export const getTopmenusList = R.path(['topmenus']);

export const getTopmenusTotal = state => parseInt(R.path(['total'])(state), 10);
export const getTopmenusPage = state => parseInt(R.path(['page'])(state), 10);
export const getTopmenusPageSize = state => parseInt(R.path(['pageSize'])(state), 10);
export const getTopmenusLoading = state => state.loading;
export const getTopmenusLoaded = state => state.loaded;

export const getTopmenu = R.path(['topmenu']);

export const getTopmenuTypes = R.path(['topmenuTypes']);

export const getTopmenusActivityTypesSelected = R.path(['filters', 'activity']);
export const getTopmenusTypesSelected = R.path(['filters', 'types']);
