import React from 'react';
import {connect} from 'react-redux';
import Filter from '../../../../components/Filter';
import {
  loadTopmenusData,
  toggleActivityType,
  toggleType,
  loadTopmenuTypes
} from '../../../../modules/layouts/topmenu/index';
const {
  getTopmenusActivityTypesSelected,
  getTopmenusTypesSelected,
  getTopmenuTypes
} = require('../../../../selectors').default;

const topmenuActivityFilterOptions = [
  {id: '0', name: 'Нет'},
  {id: '1', name: 'Да'}
];

class TopmenusListSidebar extends React.PureComponent {
  componentDidMount() {
    this.props.loadTopmenuTypes();
  }
  render() {
    return (
      <div>
        {this.renderActivityFilter()}
        {this.renderTopmenuTypesFilter()}
      </div>
    );
  }
  renderActivityFilter() {
    return <Filter
      label="Активность"
      items={topmenuActivityFilterOptions}
      selected={this.props.activityTypesSelected}
      onToggle={this.props.toggleActivityType}
    />;
  }
  renderTopmenuTypesFilter() {
    return <Filter
      label="Тип"
      items={this.props.types}
      selected={this.props.typesSelected}
      onToggle={this.props.toggleType}
    />;
  }
}

export default connect(
  state => ({
    activityTypesSelected: getTopmenusActivityTypesSelected(state),
    typesSelected: getTopmenusTypesSelected(state),
    types: getTopmenuTypes(state)
  }),
  dispatch => ({
    loadTopmenuTypes() {
      dispatch(loadTopmenuTypes());
    },
    toggleActivityType(id) {
      dispatch(toggleActivityType(id));
      dispatch(loadTopmenusData());
    },
    toggleType(id) {
      dispatch(toggleType(id));
      dispatch(loadTopmenusData());
    }
  })
)(TopmenusListSidebar);
